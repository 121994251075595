import React from 'react'

import { useState, useEffect, useContext } from 'react'


import RedeemIcon from '@mui/icons-material/Redeem';

import './Glow.css'


import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'


import sonic_pitches from './Images/SonicVillage.png'

import baked_pitches from './Images/BakedNation.png'

import underworld_pitches from './Images/UnderWorld.png'

import Footer from './Footer'


import styled from 'styled-components';


import {Provider} from 'components/ClaimContext'

import Connect from './Connect'

import Cart from './Cart/Cart'

import ConnectPopUp from './ConnectPopUp'

import ClaimRewards from './ClaimRewards'

import AlertPopUp from './AlertPopUp'

import ClayMap from '../Map/MemeCabin'

import { createGlobalStyle } from "styled-components";

import {MainClaimContext} from '../ClaimContext'


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import serial_dictionary from 'serial_dictionary.json'
import plot_info from 'detailed_plot_info.json'

import full_locked_info from './updated_stake_full_info.json'


const CLAIM_SCRIPT_ADDRESS = 'addr1q863g7amena708fghevv243j5xm0ru60lu54ql4ru7w08606lg9swmkq4tgszs5dn90rw9j3t0m8qve5npqfv3revjzsvrf0r7'
const PITCHES_POLICY = '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e'





const MintButton = styled.button`
 background-color: #ED1A70;
 max-width: 120px;
 max-height: 60px;
 width: 100%; 
 height: 100%;
 border-radius: 1rem;

 border: none;

 font-family: ButtonFont;
 font-size: 2rem;
 text-align: center;
 
 color: beige;

 cursor: pointer;
 
 letter-spacing: 0.15rem;
 z-index: 1;

 font-family: ClayNationFont;

 &:hover{
 	 background-color: #5870F6;
 }

 width: 120px;
 height: 60px;
`



const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


  @media screen and (max-width: 680px) {
    font-size: 34px; 
  }
   
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);

  @media screen and (max-width: 680px) {
    font-size: 30px; 
  }

`;


const NavBar = styled.div`
    position: absolute;
    top: 10px;
    right: 0px; 
    z-index: 1001;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 90%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 30px; 
    margin-top: 20px;
    background: transparent;
    transition: ease-in-out all 0.3s;  
`


// const Container = styled.div`
//   width: 100%; 
//   height: 100%;
//   background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ls_sonicvillage_land.png');
//   background-size: cover;
//   background-repeat: no-repeat;

//   overflow: hidden;
// `;


const Container = styled.div`


background: radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%);
background-blend-mode: overlay, normal;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
height: 100vh;
height: 100svh;


width: 100%; 
height: 100%;
overflow: hidden;




`


const Backdrop = styled.div`
    background-color: rgba(10,10,10, 0.2);
    backdrop-filter: blur(15px);
`


const CNLogoContainer = styled.div`
    width:'200px', 
    height:'90%', 
    backgroundColor:'', 
    marginLeft:'35px', 
    display:'flex', 
    alignItems:'center'
`


const CNLogo = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
` 


const TreeImage = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
`


const NavBarUpdated = styled.div`
  width: 90%;
  height: 12%;
  background:  var(--background);
  background-blend-mode: overlay;
  position: fixed;
  top: 7%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`


//https://claymarket.io/pitches/staking/

async function getStakingInfo(stake_address){

  let temp_stake_address = 'empty'

  if (stake_address != ''){
    temp_stake_address = stake_address
  }

  const protocolParams = await fetch('https://claymarkets.com/pitches/staking/' + temp_stake_address + '/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}



const usePointerGlow = () => {
  const [status, setStatus] = React.useState(null)
  React.useEffect(() => {
    const syncPointer = ({ x: pointerX, y: pointerY }) => {
      const x = pointerX.toFixed(2)
      const y = pointerY.toFixed(2)
      const xp = (pointerX / window.innerWidth).toFixed(2)
      const yp = (pointerY / window.innerHeight).toFixed(2)
      document.documentElement.style.setProperty('--x', x)
      document.documentElement.style.setProperty('--xp', xp)
      document.documentElement.style.setProperty('--y', y)
      document.documentElement.style.setProperty('--yp', yp)
      setStatus({ x, y, xp, yp })
    }
    document.body.addEventListener('pointermove', syncPointer)
    return () => {
      document.body.removeEventListener('pointermove', syncPointer)
    }
  }, [])
  return [status]
}



function PitchesStakingData(props){

    const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)


    const {
        setSonicS,setSonicM,setSonicL,setSonicTotal,
        setUnderworldS,setUnderworldM,setUnderworldL,setUnderworldTotal,
        setBakedS, setBakedM, setBakedL, setBakedTotal,
        setPitchesScore, setLocationScore, setExtraBonusesScore, setYourTotalXP,
        setPitchesRewards, setLocationBonuses, setExtraBonuses, setYourTotalRewards,
        setEpoch, setXpPool, setRewardsPool, setBonusAreas, pitches, setPitches,
        bonusAreas, setIsEligible

    } = props


    function formatAmount(amount){

            if (amount < 1000){
                return String(amount)
            }else if (amount < 1000000){
                return String((amount/1000).toFixed(2)) + 'K'
            }else{
                return String((amount/1000000).toFixed(2)) + 'M'
            }

    }


    function get_pitch_points(x_y){
        let cur_points = 0

        let cur_info = plot_info[x_y]

        let size = cur_info['size']

        if (size == 's'){
            cur_points = 50
        }else if (size == 'm'){
            cur_points = 100
        }else if (size == 'l'){
            cur_points = 150
        }

        return cur_points
    }


    function get_pitch_zone(x_y){
        let cur_info = plot_info[x_y]
        let zone = cur_info['zone']
        return zone
    }

    function get_pitch_size(x_y){
        let cur_info = plot_info[x_y]
        let size = cur_info['size']
        return size
    }

    function get_pitch_coords(unit){
        let temp = unit.slice(56,)
        temp = Buffer.from(temp, 'hex').toString('utf8')

        let serial = temp.slice('ClayNationPitch'.length,)
        let coordinates = serial_dictionary[String(serial)]

        let x = coordinates[0]
        let y = coordinates[1]

        let x_y = '(' + String(x) + ',' + String(y) + ')'

        return x_y
    }

    function getDistance(x1,y1,x2,y2){
        let y = x2-x1
        let x=y2-y1
        return Math.sqrt(x*x+y*y)
    }

    function get_location_bonus(x_y, bonus_areas){

        let location_bonus = 0

        let x = parseInt(x_y.split('(')[1].split(',')[0],10)
        let y = parseInt(x_y.split(')')[0].split(',')[1],10)

        let max_bonus = 50

        bonus_areas.forEach((e)=>{
            let cur_distance = getDistance(parseInt(e[0],10), parseInt(e[1],10), x,y)

            if (cur_distance <= parseInt(e[2],10) + 1){
                location_bonus += 10 + Math.round((parseInt(e[2],10) - cur_distance)/parseInt(e[2],10) * max_bonus)
            }

        })


        let size = get_pitch_size(x_y)

        if (size == 's'){
            //do nothing
        }else if (size == 'm'){
            location_bonus = 2 * location_bonus
        }else if (size == 'l'){
            location_bonus = 3 * location_bonus
        }

        return location_bonus

    }

    function get_extra_bonus(x_y){
        return 0
    }

    function get_pitch_zone(x_y){
        let cur_info = plot_info[x_y]
        let zone = cur_info['zone']
        return zone
    }


    function get_pitches_breakdown(pitches, bonusAreas){
        let sv = []
        let uw = []
        let bn = []

        let pitches_score = 0
        let location_score = 0
        let extra_bonuses_score = 0

        pitches.forEach((pitch) =>{
            let x_y = get_pitch_coords(pitch)
            let size = get_pitch_size(x_y)
            let zone = get_pitch_zone(x_y)

            let pitch_points = get_pitch_points(x_y)

            pitches_score += pitch_points

            let location_points = get_location_bonus(x_y, bonusAreas)

            location_score += location_points

            let bonus_points = get_extra_bonus(x_y)

            extra_bonuses_score += bonus_points

            if (zone == 'sv'){
                sv.push(size)
            }else if (zone == 'uw'){
                uw.push(size)
            }else if (zone == 'bn'){
                bn.push(size)
            }
        })

        let sv_s = sv.filter(c => c === 's').length;
        let sv_m = sv.filter(c => c === 'm').length;
        let sv_l = sv.filter(c => c === 'l').length;
        let sv_total = sv_s + sv_m + sv_l


        let uw_s = uw.filter(c => c === 's').length;
        let uw_m = uw.filter(c => c === 'm').length;
        let uw_l = uw.filter(c => c === 'l').length;
        let uw_total = uw_s + uw_m + uw_l


        let bn_s = bn.filter(c => c === 's').length;
        let bn_m = bn.filter(c => c === 'm').length;
        let bn_l = bn.filter(c => c === 'l').length;
        let bn_total = bn_s + bn_m + bn_l


        setSonicS(sv_s)
        setSonicM(sv_m)
        setSonicL(sv_l)
        setSonicTotal(sv_total)

        setUnderworldS(uw_s)
        setUnderworldM(uw_m)
        setUnderworldL(uw_l)
        setUnderworldTotal(uw_total)

        setBakedS(bn_s)
        setBakedM(bn_m)
        setBakedL(bn_l)
        setBakedTotal(bn_total)


        setPitchesScore(pitches_score)
        setLocationScore(location_score)
        setExtraBonusesScore(extra_bonuses_score)

        setYourTotalXP(pitches_score+location_score+extra_bonuses_score)


        // setPitchesScore(parseInt(pitchesInfo['pitches_score'],10))
        // setLocationScore(parseInt(pitchesInfo['location_score'],10))
            
        // setExtraBonusesScore(parseInt(pitchesInfo['extra_bonuses_score'],10))
        // setYourTotalXP(parseInt(pitchesInfo['total_score'],10))

    }


    async function fecthInfo(){


        if (full_locked_info.hasOwnProperty(stake_address)){
            setIsEligible(true)
        }else{
            setIsEligible(false)
        }




        return




        let stakingInfo = await getStakingInfo(stake_address)

        //console.log(stakingInfo)

        if (stakingInfo.hasOwnProperty('pitches_info')){


            // for (let token in tokens){
            //     console.log(token)
            // }


            // console.log('TOKENS')
            // console.log(tokens)


            let pitchesInfo = stakingInfo['pitches_info']
            let cur_pitches = pitchesInfo['pitches']


            let all_pitches = []

            // console.log(pitchesInfo)

            // console.log('CURRENT PITCHES')
            // console.log(cur_pitches)

            if (cur_pitches){
                all_pitches = cur_pitches
            }

            tokens.forEach(
                (token)=>{
                    //console.log(token)

                    let cur_unit = token['unit']

                    if (cur_unit.slice(0,56) == PITCHES_POLICY){
                        if (!all_pitches.includes(cur_unit)){
                            all_pitches.push(cur_unit)
                        }
                    }
            })

           

            setPitches(all_pitches)


            //get_pitches_breakdown(all_pitches, pitchesInfo['bonus_areas'])


           



            // let sv = pitchesInfo['sv']
            // let sv_s = parseInt(sv['s'], 10)
            // let sv_m = parseInt(sv['m'], 10)
            // let sv_l = parseInt(sv['l'], 10)

            // let sv_total = sv_s + sv_m + sv_l


            // let uw = pitchesInfo['uw']
            // let uw_s = parseInt(uw['s'], 10)
            // let uw_m = parseInt(uw['m'], 10)
            // let uw_l = parseInt(uw['l'], 10)

            // let uw_total = uw_s + uw_m + uw_l


            // let bn = pitchesInfo['bn']
            // let bn_s = parseInt(bn['s'], 10)
            // let bn_m = parseInt(bn['m'], 10)
            // let bn_l = parseInt(bn['l'], 10)

            // let bn_total = bn_s + bn_m + bn_l



            // setSonicS(sv_s)
            // setSonicM(sv_m)
            // setSonicL(sv_l)
            // setSonicTotal(sv_total)

            // setUnderworldS(uw_s)
            // setUnderworldM(uw_m)
            // setUnderworldL(uw_l)
            // setUnderworldTotal(uw_total)

            // setBakedS(bn_s)
            // setBakedM(bn_m)
            // setBakedL(bn_l)
            // setBakedTotal(bn_total)


            // setPitchesScore(parseInt(pitchesInfo['pitches_score'],10))
            // setLocationScore(parseInt(pitchesInfo['location_score'],10))
            

            // setExtraBonusesScore(parseInt(pitchesInfo['extra_bonuses_score'],10))
            // setYourTotalXP(parseInt(pitchesInfo['total_score'],10))


            setPitchesRewards(formatAmount(parseInt(pitchesInfo['pitches_rewards'],10)))
            setLocationBonuses(formatAmount(parseInt(pitchesInfo['location_bonuses'],10)))
            setExtraBonuses(formatAmount(parseInt(pitchesInfo['extra_bonuses'],10)))
            setYourTotalRewards(formatAmount(parseInt(pitchesInfo['total_rewards'],10)))

            setEpoch(parseInt(pitchesInfo['epoch'],10))
            setXpPool(formatAmount(parseInt(pitchesInfo['xp_pool'],10)))
            setRewardsPool(formatAmount(parseInt(pitchesInfo['rewards_pool'],10)))


            // setBonusAreas([
            //     [0,0,80],
            //     [100,200,80],
            //     [180,-100,90]
            // ])

            setBonusAreas(pitchesInfo['bonus_areas'])

        }
    }


    useEffect(()=>{

        get_pitches_breakdown(pitches, bonusAreas)

    },[pitches, bonusAreas])



    useEffect(() => {

        if (stake_address !== ''){
            fecthInfo()
        }else{
            fecthInfo()
        }

        const intervalCall = setInterval(() => {
            // console.log('\n\n\n\n\n')
            // console.log(stake_address)
            // console.log('\n\n\n\n\n')


            if (stake_address !== ''){
                fecthInfo()
            }
        }, 30000);
        
        return () => {
            clearInterval(intervalCall);
        };
        
    },[stake_address])




    return(null)
}




function PitchesStaking(props){

	const [status] = usePointerGlow();


	const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [mintAsset, setMintAsset] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')

    const [buttonPopup, setButtonPopup] = useState(false);

    const theme = useTheme();

	const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
	const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
	const matchesM = useMediaQuery(theme.breakpoints.up('md'));
	const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
	const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));





    const GlobalStyle = createGlobalStyle`
        :root {
            --background:  rgba(28, 37, 52, 0.5);

             --background-hover:  rgba(28, 37, 52, 0.9);
              --clayblue-primary: #1E84FC;
              --clayblue-secondary:  #5d96ffcd;
              --claymint-primary: #a0fac8;

              font-family: ButtonFont;

        }
        `
	const [isPopUpOpen, setIsPopUpOpen] = useState(false)

	const [activeWallet, setActiveWallet] = useState('')

	const [stake_address, set_stake_address] = useState('')

	const [isBusy, setIsBusy] = useState(false)
	const [updateWallet, setUpdateWallet] = useState(false)
	const [walletToEnable, setWalletToEnable] = useState('')


	const [cartItems, setCartItems] = useState([])

	const [clayPayOpen, setClayPayOpen] = useState(false)







  const [fetchingFilteredAssets, setFetchingFilteredAssets] = useState(true)
  const [isAnalytics, setIsAnalytics] = useState(false)
  const [isSellOpen, setIsSellOpen] = useState(false)
  const [sellAsset, setSellAsset] = useState(null)
  const [curActiveWallet, setCurActiveWallet] = useState('')
  const [chosenCollection, setChosenCollection] = useState('')
  const [appliedFilters, setAppliedFilters] = useState({})
  const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(true)

  const [yourPitches, setYourPitches] = useState(false)

  const [stickPointers, setStickPointers] = useState(true)
  const [circlePointers, setCirclePointers] = useState(true)
  const [opacityFilter, setOpacityFilter] = useState(false)




  const [pitchesFound, setPitchesFound] = useState(0)



	const pitchesFilters = {
                                yourPitches:yourPitches,
                                setYourPitches: setYourPitches,

                                stickPointers: stickPointers,
                                setStickPointers: setStickPointers,

                                circlePointers: circlePointers,
                                setCirclePointers: setCirclePointers,

                                opacityFilter: opacityFilter,
                                setOpacityFilter: setOpacityFilter
                            }


    const [selectedPlot, setSelectedPlot] = useState(null)
    const [sortOrder, setSortOrder] = useState('price_asc')
    const [yourAssets, setYourAssets] = useState(false)
    const [listedOnly, setListedOnly] = useState(false)
    const [notListedOnly, setNotListedOnly] = useState(false)
    const [raffleAsset, setRaffleAsset] = useState(null)

    const yourAssetsFilters = {

        yourAssets: yourAssets,
        setYourAssets: setYourAssets,

        listedOnly: listedOnly,
        setListedOnly: setListedOnly,

        notListedOnly: notListedOnly,
        setNotListedOnly: setNotListedOnly

    }


    const [searchQuery, setSearchQuery] = useState('')
    const [featuredRaffle, setFeaturedRaffle] = useState(null)


    const [pitches, setPitches] = useState([])



  //   function resetFilters() {

  //   //console.log('RESET FILTERS')

	//     setOtherFilters({})
	//     setTraitFilters({})
	//     //setCheckedAttributes([])
	//     //setOpenCategories([])
	//     //setOpenCategoryTabs([])
	//     //setSearchBarMode(false)
	//     setClayOnly(false)

	//     //setPriceValue([unscaleValue(absMin), unscaleValue(absMax)])

	//     setYourAssets(false)


	//     pitchesFilters.setStickPointers(true)
	//     pitchesFilters.setCirclePointers(true)


	//     setSearchQuery('')

	//     setSortOrder('price_asc')
  // }





  const [clayOnly, setClayOnly] = useState(false)
  const [canApply, setCanApply] = useState(false)
  
  const [absMin, setAbsMin] = useState(260)
  const [absMax, setAbsMax] = useState(2500000)
  
  const [minPrice, setMinPrice] = useState('0')
  const [maxPrice, setMaxPrice] = useState('1000000')
  
  const [priceValue, setPriceValue] = useState([0,100])
  const [priceLock, setPriceLock] = useState(false)


  const [traitFilters, setTraitFilters] = useState({})
  const [otherFilters, setOtherFilters] = useState({})    

  const [searchClassName, setSearchClassName] = useState('inputField') 






  const [epoch, setEpoch] = useState('-')
  const [xpPool, setXpPool] = useState('-')
  const [rewardsPool, setRewardsPool] = useState('-')

  const [sonicS, setSonicS] = useState('-')
  const [sonicM, setSonicM] = useState('-')
  const [sonicL, setSonicL] = useState('-')

  const [sonicTotal, setSonicTotal] = useState('-')



  const [underworldS, setUnderworldS] = useState('-')
  const [underworldM, setUnderworldM] = useState('-')
  const [underworldL, setUnderworldL] = useState('-')

  const [underworldTotal, setUnderworldTotal] = useState('-')



  const [bakedS, setBakedS] = useState('-')
  const [bakedM, setBakedM] = useState('-')
  const [bakedL, setBakedL] = useState('-')

  const [bakedTotal, setBakedTotal] = useState('-')


  const [pitchesScore, setPitchesScore] = useState('-')
  const [locationScore, setLocationScore] = useState('-')
  const [extraBonusesScore, setExtraBonusesScore] = useState('-')

  const [yourTotalXP, setYourTotalXP] = useState('-')

  const [pitchesRewards, setPitchesRewards] = useState('-')
  const [locationBonuses, setLocationBonuses] = useState('-')
  const [extraBonuses, setExtraBonuses] = useState('-')

  const [yourTotalRewards, setYourTotalRewards] = useState('-')


  const [bonusAreas, setBonusAreas] = useState([])



  const [isEligible, setIsEligible] = useState(false)



    useEffect(() => {

    	//setYourAssets(true)

    },[])



    function formatAmount(amount){

            if (amount < 1000){
                return String(amount)
            }else if (amount < 1000000){
                return String((amount/1000).toFixed(2)) + 'K'
            }else{
                return String((amount/1000000).toFixed(2)) + 'M'
            }

    }



    




	return(
		<div className='pitchesApp'
            style={{
                letterSpacing: '1px'
            }}
        >

			
		<Provider>

        <PitchesStakingData 

            setSonicS={setSonicS} setSonicM={setSonicM} setSonicL={setSonicL} setSonicTotal={setSonicTotal}
            setUnderworldS={setUnderworldS} setUnderworldM={setUnderworldM} setUnderworldL={setUnderworldL} setUnderworldTotal={setUnderworldTotal}
            setBakedS={setBakedS} setBakedM={setBakedM} setBakedL={setBakedL} setBakedTotal={setBakedTotal}
            setPitchesScore={setPitchesScore} setLocationScore={setLocationScore} setExtraBonusesScore={setExtraBonusesScore} setYourTotalXP={setYourTotalXP}
            setPitchesRewards={setPitchesRewards} setLocationBonuses={setLocationBonuses} setExtraBonuses={setExtraBonuses} setYourTotalRewards={setYourTotalRewards}
            setEpoch={setEpoch} setXpPool={setXpPool} setRewardsPool={setRewardsPool} setBonusAreas={setBonusAreas}
            pitches={pitches} setPitches={setPitches} bonusAreas={bonusAreas} setIsEligible={setIsEligible} isEligible={isEligible}
        />


        <NavBar>

          <NavBarContents>

            <CNLogoContainer
                style={{cursor:'pointer'}}

                onClick={()=>{
                    console.log('click')
                    window.open('/','_self')
                }}
            >
                <CNLogo src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" />
            </CNLogoContainer>

            <div style={{marginRight:'35px'}}>
                
                <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    set_stake_address={set_stake_address}
                />


                {/*<div style={{cursor:'pointer', display:'flex', width:'100%', height:'100%',
                justifyContent:'end', alignItems:'end', backgroundColor:'blue'}}>
                    <Cart cartItems={cartItems} clayPayOpen={clayPayOpen} setClayPayOpen={setClayPayOpen} />
                </div>*/}

            </div>

         </NavBarContents>

        </NavBar>





         <div style={{position:'relative', top:'0px', width:'100%', 
            height:'100vh', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'start', alignItems:'center', minHeight:'800px'
        }}>
  

            <div style={{width: matchesS ? '100%' : '100%', display:'flex', 
                        visibility: isPopUpOpen ? 'hidden' : 'visible', alignItems:'center', 
                        height:'90%', backgroundColor:''}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'100%',flexDirection:'column'}}>
                   
                    <div style={{marginBottom: '80px', fontSize:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',
                        marginTop:'0px'
                    }}>
                        
                    <div
                        style={{

                        }}
                    >
                        

                    </div>


                    </div>



			<main>



            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>

                    <div style={
                        {fontFamily:'ClayNationFont', display:'flex', justifyContent:'center', alignItems:'space-between', width:'80%', color:'white', fontSize: matchesM ? '70px' : matchesS ? '50px' : '30px', height:'60px'}}>
                        Christmas Presents
                    </div>

                </div>



            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                    <article data-glow style={{aspectRatio:'', width: matchesS ? '95%' : '85%', height:'680px'}}>



                    <div style={{overflow:'hidden'}}>

                        <ClayMap pitchesFilters={pitchesFilters} 
                                 selectedPlot={selectedPlot}
                                 setSelectedPlot={setSelectedPlot}
                                 appliedFilters={appliedFilters}

                                 yourAssetsFilters={yourAssetsFilters}

                                 searchQuery={searchQuery}
                                 setSearchQuery={setSearchQuery}


                                 searchQuery={searchQuery}
                                 setSearchQuery={setSearchQuery}


                                 pitchesFound={pitchesFound}
                                 setPitchesFound={setPitchesFound}

                                 bonusAreas={bonusAreas}

                                 pitches={pitches}

                        />



                        {isEligible && false?
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', bottom:'40px', left:'40px'}}>
                                <ClaimRewards walletConnected={true} setClaimStake={()=>{}} CLAIM_SCRIPT_ADDRESS={CLAIM_SCRIPT_ADDRESS} />
                            </div>
                        : null}






                        </div>

                        

                    </article>
                  </div>





                   <div style={{fontFamily:'ClayNationFont', display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>

                        <article data-glow style={{aspectRatio:'', width: matchesS ? '95%' : '85%', height: matchesL ? '100px' : matchesM? '100px' : matchesS ? '100px' : '120px'}}>

                           <div style={{width:'100%', height:'100%'}}>


                                        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',
                                            fontSize: matchesL ? '38px' : matchesM ? '36px' : matchesS ? '32px' : '28px', color:'white', backgroundColor:''
                                        }}>


                                        {true?

                                            <div style={{fontSize: matchesS? '15px' : '12px', fontFamily:'ShortStack',  width:'90%', backgroundColor:''}}>
                                                
                                                {'* Claiming period has ended. Presents will arrive soon.'.toUpperCase()}
                                                                              
                                            </div>

                                            : 


                                            <div style={{fontSize: matchesS? '15px' : '12px', fontFamily:'ShortStack',  width:'90%', backgroundColor:''}}>
                                                
                                              
                                                {'* Only participants of Baked Nation locking or public mint phases are eligible to claim.'.toUpperCase()} <br/>
                                                {'Make sure to connect the same wallet you used for locking assets and/or getting public mint pass.'.toUpperCase()}
                                                
                                            
                                            </div>


                                            }

                                            

                                        </div>

                                     </div>

                        </article>

                    </div>






                   <div style={{width:'100%', height:'22%'}}>


                                <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',
                                    fontSize: matchesL ? '38px' : matchesM ? '36px' : matchesS ? '32px' : '28px', color:'white', marginTop:'5px'
                                }}>

                                    

                                </div>

                             </div>






               






        {/*
				<div style={{height:'20px'}}>

		      	</div>


				<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
		      		<article data-glow style={{aspectRatio:'', width: matchesS ? '95%' : '85%', height:'680px'}}>



		      		<div style={{overflow:'hidden'}}>

				            
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'auto'}}>
                            <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CABIN.jpeg' style={{objectFit:'contain', maxWidth:'600px'}} />
                        </div>


				    </div>

		      			

			      	</article>
			      </div>*/}



			</main>


			<Footer />


			</div>
			</div>
			</div>






        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}






			 <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />


			</Provider>
			
		</div>
	)
}

export default PitchesStaking;


