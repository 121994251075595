import Button from '@mui/material/Button';
import { useRef, useState, useEffect, useContext} from 'react'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import DownloadIcon from '@mui/icons-material/Download';
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton';


import {Address, BaseAddress, Value} from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'


import './CN-CARD.css'
import './GC-CARD.css'

import './ModelView.css'

import ModelView from './ModelView'
import DownloadOptions from './DownloadOptions'

import {MainClaimContext} from './WalletContext'


function Card(props) {

  const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)


  const collection = (props.collection).toUpperCase()
  const serial = props.serial
  const image = props.image
  const background = props.background

  const model3D = props.model3D
  const setModel3D = props.setModel3D

  const authToken = props.authToken
  const setAuthToken = props.setAuthToken

  const [isDownload, setIsDownload] = useState(false)

  const downloadRef = useRef()


  const clayInfo = {'cn':{policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728',base_name:'ClayNation'}, 'gc':{policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768',base_name:'ClayCharlotte'}}
  


  const test_temp_link = "https://nyc3.digitaloceanspaces.com/claynation/cnmodels3d/Wizard_Hat.fbx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=IVORC6TYLT74U7XQQBFK%2F20220903%2Fnyc3%2Fs3%2Faws4_request&X-Amz-Date=20220903T200656Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=6a63924e165a75653857c29889b1531ca0bd52b20c2eca7aa3c736d336bfa428"


  const [modelUrl, setModelUrl] = useState('')

   async function view2D(){
    setModel3D('')
  }


    async function  signMessage(message){
        let cardano;
        cardano = await window.cardano[activeWallet]
        cardano = await cardano.enable()
        let encoded_message_string = Buffer.from(message).toString('hex')
        let addr_ptr = Address.from_bech32(address)
        let address_encoded = Buffer.from(addr_ptr.to_bytes()).toString('hex')
        let access_signature = await cardano.signData(address_encoded, encoded_message_string)

        //console.log(access_signature)

        if (activeWallet != 'flint'){
          access_signature = access_signature.signature
        }
        
        //console.log(access_signature)
        return access_signature
  }


    async function verifyOwnership(){

        let r = await fetch('https://cnholders.com/verification/authtoken/'+address +'/');
        let r_json = await r.json();
        let message = r_json.msg_to_sign
        let signature = await signMessage(message)


        let _data = {
                signature: String(signature)
        }


        let auth_request = await fetch('https://cnholders.com/verification/message/test/', {
        method: 'POST',
        body: JSON.stringify(_data),
        headers: {"Content-type": "application/json; charset=UTF-8"}
        })

        let auth_request_json = await auth_request.json();


        let authtoken = auth_request_json.authtoken


        //updateAuthCookies(address,authtoken)

        return authtoken

  }


  function checkAuthTokenCookies(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       //console.log(cookies_dict)
       return cookies_dict[stake_address]
  }

async function updateAuthTokenCookies(cur_auth_token){
    let date = new Date();
    let expDays = 120
    let expTime = expDays * 24 * 60 * 60 * 1000
    date.setTime(date.getTime() + expTime);
    const expires = "expires=" + date.toUTCString();
    document.cookie = String(stake_address) + " = " + String(cur_auth_token) + '; ' + expires + "; path=/"
}


async function accessRequest(asset_id, file_format, data){
            let model_request = await fetch(('https://cnholders.com/access/model/'+asset_id+'/format/'+file_format+'/'), {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {"Content-type": "application/json; charset=UTF-8"}
        })

        let model_request_json = await model_request.json();

        return model_request_json.src
}


   async function accessModel3D(asset_id, file_format)  {
      let data;
      let cur_auth_token;
      let model_src; 

      let is_verified_holder = false

      if (address == 'Verified Holder'){
          is_verified_holder = true
                data = {
                        authtoken: String(authToken),
                        discord: 'True'
                }

                model_src = await accessRequest(asset_id, file_format, data)

        }else{
             
                cur_auth_token = checkAuthTokenCookies()

                if (typeof(cur_auth_token) == 'undefined'){
                  cur_auth_token = await verifyOwnership()

                  data = {
                          authtoken: String(cur_auth_token)
                  }

                  model_src = await accessRequest(asset_id, file_format, data)

                }else{

                  data = {
                          authtoken: String(cur_auth_token)
                  }

                  try{
                    model_src = await accessRequest(asset_id, file_format, data)
                  }catch{
                    cur_auth_token = await verifyOwnership()
                    model_src = await accessRequest(asset_id, file_format, data)
                  }   
            }
        }


    if (!is_verified_holder){
       updateAuthTokenCookies(cur_auth_token)
    }   


    return model_src
  }




  async function view3D(model_name, collection, serial){
   
    //console.log(background)

    const asset_id = clayInfo[collection]['policy_id'] + Buffer.from(clayInfo[collection]['base_name'] + String(serial)).toString('hex')

    let file_format = 'fbx'
    const model_src = await accessModel3D(asset_id, file_format)

    setModelUrl(model_src)
    setModel3D(model_name)
  }






/*
    const accessModel3D = async (authtoken, asset_id, file_format) => {


        let data;
        if (address == 'Verified Holder'){
                data = {
                        authtoken: String(authtoken),
                        discord: 'True'
                }


        }else{
                
                data = {
                        authtoken: String(authtoken)
                }
        }

        let model_request = await fetch(('https://cnholders.com/access/model/'+asset_id+'/format/'+file_format+'/'), {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {"Content-type": "application/json; charset=UTF-8"}
        })

        let model_request_json = await model_request.json();

        console.log(model_request_json)


        return model_request_json.src
  }
*/

  return (


     <div className={collection+"-flip-card_2"} style={{marginTop:'60px'}} >

        <div style={{position:'absolute', top:'10px', right:'10px', zIndex:'1000'}}>

        <IconButton ref={downloadRef}
          onClick={() => {
            //console.log('Download button pressed')
            setIsDownload(!isDownload)
          }}
        >
        <DownloadIcon style={{ color: 'rgb(38, 58, 72)'}} />
        </IconButton>

        
        {isDownload ?
          <DownloadOptions accessModel3D={accessModel3D} isDownload={isDownload} setIsDownload={setIsDownload} downloadRef={downloadRef} textColor={collection == 'CN' ? "#FF9C83" : "#53C9EE"} model_name={collection.toLowerCase() + '#' + serial} collection={collection.toLowerCase()} />
        :
        null}


         </div>
   
          <div className={collection+"-flip-card-front_2"}>
       

          <img src={"/"+collection+"_CARD.svg"} style={{width:'400px'}}/>


          {model3D == collection.toLowerCase() + '#' + serial ?

          <div className="canvas_container" id={collection.toLowerCase() + '#' + serial} >
            <ModelView collection={collection.toLowerCase()} background_col={background} model_name={collection.toLowerCase() + '#' + serial} model_url={modelUrl} />
          </div>
          :          

          <img className={collection+'-clay-nft_2'} src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/"+image+".png"}
          style={{width:'323px', height:'auto', marginLeft:'11.5px'}} />
        }
         
          <div className={collection+"-clay-id_2"}> {collection}#{serial} </div>

       
         <span className={collection+"-tokens_2"}>


         {model3D == collection.toLowerCase() + '#' + serial ?

         <Chip variant="outlined" label="Back to 2D" color="primary"

         style={{fontFamily:'shortstack', color: collection=='CN' ? "#FF9C83" : "#53C9EE" , borderColor:collection=='CN' ? "#FF9C83" : "#53C9EE"}}

         onClick={()=>{
          view2D()
         }}

         />

         : 


          <Chip variant="outlined" icon={<ThreeDRotationIcon />} label="View Model" color="primary" 
          
           style={{fontFamily:'shortstack', color: collection=='CN' ? "#FF9C83" : "#53C9EE" , borderColor:collection=='CN' ? "#FF9C83" : "#53C9EE"}}

          onClick={()=>{
            const model_name = collection.toLowerCase() + '#' + serial

            view3D(model_name, collection.toLowerCase(), serial)
          }} 

          />

        }



         </span> 
      
      </div>
    </div>



  );
}

export default Card;
