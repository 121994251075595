import { useState, useEffect, useContext} from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import RedeemIcon from '@mui/icons-material/Redeem';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {MainClaimContext} from '../ClaimContext'

import styled from 'styled-components';

const MintButton = styled.button`
 background-color: #ED1A70;
 max-width: 80px;
 max-height: 60px;
 width: 100%; 
 height: 100%;
 border-radius: 1rem;

 border: none;

 font-family: ButtonFont;
 font-size: 2rem;
 text-align: center;
 
 color: beige;

 cursor: pointer;
 
 letter-spacing: 0.15rem;
 z-index: 1;

 font-family: ClayNationFont;

 &:hover{
     background-color: #5870F6;
 }

 width: 120px;
 height: 60px;
`


const CLAIM_SCRIPT_ADDRESS = 'addr1q863g7amena708fghevv243j5xm0ru60lu54ql4ru7w08606lg9swmkq4tgszs5dn90rw9j3t0m8qve5npqfv3revjzsvrf0r7'


function ClaimRewards(props) {


	const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)




    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, isVoteOpen, voteOption, voteProposal, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, setIsVoteOpen, setVoteOption, setVoteProposal} = useContext(MainClaimContext)

    


	const walletConnected = props.walletConnected

	const setClaimStake = props.setClaimStake

	const CLAIM_SCRIPT_ADDRESS = props.CLAIM_SCRIPT_ADDRESS




	function get_required_signers(utxos){
       

    let required_keys = []

    for (let i = 0; i < utxos.length; i ++){
        let utxo = utxos[i]
        let utxo_output_address = utxo.output().address()
        //console.log(utxo_output_address.to_bech32())



        let utxo_key_hash = Buffer.from(serLib.BaseAddress.from_address(utxo_output_address).payment_cred().to_keyhash().to_bytes()).toString('hex')

        if (!required_keys.includes(utxo_key_hash)){
            required_keys.push(utxo_key_hash)
        }

    }



    return required_keys
}



async function getUtxos(cardano){
      const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
        const utxos = (await cardano.getUtxos()).map((utxo) =>
            txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
         );
        return txUnspentOutputs
}


async function getParameters(){
  const protocolParams = await fetch("https://clayapi.com/baking/parameters/")
  const json_params = await protocolParams.json()
  return json_params
}

function compare_inputs(input_1, input_2){
    let tx_hash_1 = Buffer.from(input_1.transaction_id().to_bytes()).toString('hex')
    let tx_index_1 = input_1.index()

    let tx_hash_2 = Buffer.from(input_2.transaction_id().to_bytes()).toString('hex')
    let tx_index_2 = input_2.index()

    //console.log(tx_hash_1 + '#' + tx_index_1)
    //console.log(tx_hash_2 + '#' + tx_index_2)

    return (tx_hash_1 == tx_hash_2 && tx_index_1 == tx_index_2)
}




async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_byte = String(protocolParams.coins_per_utxo_size)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_byte(serLib.BigNum.from_str(coins_per_utxo_byte))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}




async function setInitialFunction(protocolParams, receiver_encoded){

  let txBuilder_1 = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder_1.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+500000)))
      ),
  );




  return txBuilder_1


}


async function getCurrentSlot(){
    let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                                  .then(data => data)

    slot = parseInt(slot.slot)

    return slot
}






async function sendDeposit(){

	await new Promise(r => setTimeout(r, 950));
    notifyInfo('Building transaction...')
    await new Promise(r => setTimeout(r, 1450));

    let receiver_encoded = serLib.Address.from_bech32(CLAIM_SCRIPT_ADDRESS)


    let cardano = await window.cardano[activeWallet]
    cardano = await cardano.enable()



    let address_encoded = await cardano.getUsedAddresses()
	address_encoded = address_encoded[0]
	address_encoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex"))

	let protocolParams = await getParameters()
  	const utxos = await getUtxos(cardano)


  	
  	const currentSlot = await getCurrentSlot()

  	const lovelaceToSend = 2500000


  	let test_txBuilder = await get_txBuilder(protocolParams)

  	test_txBuilder.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+2500000)))
      ),
  	);


  	const ttl = parseInt(currentSlot) + 7200



  	try{
  		test_txBuilder.add_inputs_from(utxos, 1)	
  	}catch (err){
  		notifyError("Insufficient Balance")
  		console.log(err)
  		return
  	}
  	


  	

    let test_inputs;
    let transaction_inputs = []
    let txInputs = serLib.TxInputsBuilder.new()
    let txBuilder = await get_txBuilder(protocolParams)

    try{

    
	    test_txBuilder.add_change_if_needed(address_encoded)


	    test_inputs = test_txBuilder.build_tx().body().inputs()




	    
	    for (let i = 0; i < test_inputs.len(); i++){
	    	for (let j = 0; j < utxos.len(); j ++){
	    		if (compare_inputs(utxos.get(j).input(),test_inputs.get(i))){
	    			transaction_inputs.push(utxos.get(j))
	    		}
	    	}
	    }


        let required_signers = get_required_signers(transaction_inputs)
    

        
        //error here
	    for (let i = 0; i < transaction_inputs.length; i++){
	    	txInputs.add_regular_input(address_encoded,transaction_inputs[i].input(), transaction_inputs[i].output().amount())
	    }


       


	   
	    txBuilder.set_inputs(txInputs)
	    txBuilder.set_ttl_bignum(serLib.BigNum.from_str(String(ttl)));
	                        
	    for (let i = 0; i < required_signers.length; i++){
	    	txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signers[i],'hex')))
	    }


	    txBuilder.add_output(
	      serLib.TransactionOutput.new(
	          receiver_encoded,
	          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend)))
	      ),
	  	);


	    txBuilder.add_change_if_needed(address_encoded)

	}catch (err){
        console.log(err)
		notifyError('Transaction build failed')
		return
	}



    const unsignedTx = txBuilder.build_tx()
    const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')
    
    let tx_witness;
    try{
    	tx_witness = await cardano.signTx(unsignedTx_hash,true)
    }catch(err){
    	notifyError('Transaction was not signed')
    	return
    }

    const txBody = unsignedTx.body()


    let wallet_witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))


    const totalVkeys = serLib.Vkeywitnesses.new();


    for (let i = 0; i < wallet_witness_set.vkeys().len(); i++){
                let holder_vkey = wallet_witness_set.vkeys().get(i)
                totalVkeys.add(holder_vkey)
        }


    const totalWitnesses = serLib.TransactionWitnessSet.new();
    totalWitnesses.set_vkeys(totalVkeys);


    let tx_auxiliary_data = unsignedTx.auxiliary_data()


    const signedTx = serLib.Transaction.new(
                txBody,
                totalWitnesses,
                tx_auxiliary_data
        )

    const signedTxHash = Buffer.from(
                signedTx.to_bytes()
        ).toString('hex')


    //console.log(signedTxHash)



    let submit_status;

    try{
    	submit_status = await cardano.submitTx(signedTxHash)

    	notifySuccess('Transaction has been submitted')
    	setClaimStake({})
    	return
    }catch (err){
    	notifyError((err.message).slice(0,100))
    	console.log(err.message)
    	return
    }

}



  return (
    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
               
                <MintButton
                    onClick={()=>{

                        console.log('clicked CLAIM')

                      if (walletConnected){
                        console.log('CLAIM')
                        sendDeposit()
                      }
                      
                    
                    }}
                >

                                        <RedeemIcon style={{fontSize:'50px'}} />

                 </MintButton> 
    </div>


  );
}

export default ClaimRewards;