import React from 'react'
import { useState, useContext, useEffect } from 'react';

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import './CnButton.css'

import './SearchBar.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import Divider from '@mui/material/Divider';

import styled from 'styled-components';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CircularProgress from '@mui/material/CircularProgress';



import './VotePopUp.css'
import './AlertPopUp.css'


import {MainClaimContext} from './WalletContext'


const SearchInput = styled.span`
  
    display:inline-block;
    width: 90%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`

const DropDownItem = styled.div`
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
  overflow: hidden;
  border-radius: 0px;
  opacity: 0.92;
  cursor: pointer;

  z-index: 5;

  backdrop-filter: 'blur(80px)';



:hover
{
  content: '';
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(132,211,255,1) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 100%);
  opacity: 0.92;

}


:after
{
  content: '';
  position: absolute;
  inset : 2px;
  background: linear-gradient(138deg, rgba(53,61,104,1) 0%, rgba(51,57,77,1) 44%, rgba(29,58,61,1) 100%);
  border-radius: 0px;
  z-index: 1;
  backdrop-filter: 'blur(80px)';

}
`


const ButtonText = styled.h1`
  position: relative;
  font-size: 1.8em;
  font-family: 'shortstack';
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
  color: bisque;
  z-index: 4;
`



function AlertPopUp(props) {

    const isOpen = props.isOpen
    const setIsOpen = props.setIsOpen

    const setAuthToken = props.setAuthToken


    const setIsWalletLoading = props.setIsWalletLoading

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)


    const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

    const eligible_policies = ['40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728','94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768']

    const [alertMessage, setAlertMessage] = useState()



    const [accountChange, setAccountChange] = useState()

    const [tempWalletInfo, setTempWalletInfo] = useState()

    const [isBusy, setIsBusy] = useState(false)

    const [isVerifyBot, setIsVerifyBot] = useState(false)




  const [isSearching, setIsSearching] = useState(false)


  const [clearSearch, setClearSearch] = useState(false)

  const [searchClassName, setSearchClassName] = useState('searchField')

  const [searchVal, setSearchVal] = useState('')

  const [searchPlaceholder, setSearchPlaceholder] = useState('Enter your !pass code here')


  const checkDiscordInput = async (pValue) => {
        let validInput = false

        if (pValue.length == 64){
                try{
                        let data = {
                            authtoken: String(pValue)
                        }


                        let assets_request = await fetch(('https://cnholders.com/verification/discord/assets/'), {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {"Content-type": "application/json; charset=UTF-8"}
                        })

                        let assets = await assets_request.json();

                        let cn = []
                        let gc = []

                        let cn_assets = assets.claynation
                        let gc_assets = assets.claycharlotte


                        //console.log(cn_assets)
                        //console.log(gc_assets)

                        for (let i = 0; i < cn_assets.length; i++){
                          cn.push({unit:cn_assets[i],quantity:"1"})
                        }

                        for (let i = 0; i < gc_assets.length; i++){
                          cn.push({unit:gc_assets[i],quantity:"1"})
                        }

                        cn.concat(gc)

                        setTokens(cn)

                        //console.log(cn)

                        //setClayNationBalance(clay_nation_list)

                        //setWalletName(null)
                        //setAddress('Verified Holder')


                        //updateAuthCookies('Verified Holder', pValue)

                        setAuthToken(pValue)
                        setAddress('Verified Holder')
                        setSearchVal('')
                        setIsOpen(false)
                        setIsVerifyBot(false)


                }catch{
                        notifyError('Could not obtain verified records with this authentication code!')

                }
        }
  }



async function getWalletBalance(){

  setIsOpen(false)
  
  let cur_wallet = activeWallet

  if (cur_wallet == 'yoroi'){
    notifyInfo('This may take a while...')
  }


  setSelectedTokens([])
  let cardano;
  cardano = await window.cardano[activeWallet]
  

  let address_encoded;
  let address_decoded;

  if (activeWallet != 'typhon'){
    cardano = await cardano.enable()
    address_encoded = await cardano.getUsedAddresses()
    address_encoded = address_encoded[0]
    address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()

  }else{

    /*
    console.log(cardano)
    address_encoded = await cardano.getAddress()

    address_decoded = address_encoded.data

    console.log(address_encoded)

    let typhon_balance = await cardano.getBalance()

    console.log(typhon_balance)
    */

  }

 
  //console.log(address_decoded)

  let encoded_balance = await cardano.getBalance()
  let balance_byte_str = serLib.Value.from_bytes(Buffer.from(encoded_balance, 'hex'))
  let lovelace_balance = balance_byte_str.coin().to_str()

  let current_ada_balance = (parseInt(lovelace_balance) / 1000000).toFixed(2);

  //console.log(current_ada_balance + ' ADA')

  let multiasset_balance = balance_byte_str.multiasset()
    let assets = []
    assets.push({ unit: "lovelace", quantity: lovelace_balance})

     if (multiasset_balance) {
        const multiAssets = multiasset_balance.keys()
        for (let j = 0; j < multiasset_balance.len(); j++) {
            const policy = multiAssets.get(j);
              
            const policyAssets = multiasset_balance.get(policy);
            const assetNames = policyAssets.keys();
            for (let k = 0; k < assetNames.len(); k++) {
                const policyAsset = assetNames.get(k);
                const quantity = policyAssets.get(policyAsset).to_str();
                const asset_hash = Buffer.from(policy.to_bytes(), "hex").toString("hex") + Buffer.from(policyAsset.name(), "hex").toString("hex");
                assets.push({unit: asset_hash, quantity: quantity})
            }
          }
        }

        let tokens_list = assets.slice(1)

      /*
     let tokens_list = assets.slice(1)

     let cur_eligible_assets = []

     for (let i = 0; i < tokens_list.length; i++) {
      let temp_token = String(tokens_list[i].unit)
        
        let cur_policy = temp_token.slice(0,56)
        let cur_asset = temp_token.slice(56,)
        cur_asset = Buffer(cur_asset, 'hex').toString('utf8');

        if (eligible_policies.includes(cur_policy)){
          console.log(cur_policy)
          console.log(cur_asset)
          cur_eligible_assets.push(temp_token)
      } 
    }
    */

    let temp = serLib.Address.from_bech32(address_decoded)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()


    //console.log(tokens_list)
    

    setTempWalletInfo({
      cur_wallet: cur_wallet,
      address: address_decoded,
      stake_address: cur_stake_address,
      lovelace_balance: lovelace_balance,
      tokens_list: tokens_list
    })



    /*
    setAddress(address_decoded)
    setStakeAddress(cur_stake_address)

    setLovelace(lovelace_balance)

    setTokens(tokens_list)
    */

    //setIsBusy(false)


  }


   function checkWallet(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }
       return cookies_dict['wallet']
  }

async function updateWallet(wallet){
    let date = new Date();
    let expDays = 120
    let expTime = expDays * 24 * 60 * 60 * 1000
    date.setTime(date.getTime() + expTime);
    const expires = "expires=" + date.toUTCString();
    document.cookie = String('wallet') + " = " + String(wallet) + '; ' + expires + "; path=/"
}


useEffect(() => {
    async function onAccountChange() {
      try{
      let namiAPI = await window.cardano.nami
      let isNamiEnabled = await namiAPI.isEnabled()
      //console.log(isNamiEnabled)

      if(isNamiEnabled){
        namiAPI = await namiAPI.enable()
         namiAPI.experimental.on('accountChange',()=>{
          setAccountChange('nami')
          //console.log('Nami account changed')
         })
      }

    }catch{
      //notifyError('oh no...')
    }

  }
    setTimeout(onAccountChange, 100)
    setTimeout(function checkWalletInCookies(){
    //setIsBusy(true)
    let currentWallet = checkWallet()

    //console.log('CURRENT WALLET')
    //console.log(currentWallet)

    if (typeof(currentWallet) == 'string'){
        setIsBusy(true)
        setActiveWallet(currentWallet)
        enableWallet(currentWallet)
    }

  }, 100)
}, [])



useEffect(() => {
  if(activeWallet == accountChange){
    //console.log('Switching...')
    setAccountChange()
    getWalletBalance()
  }else{
    if(accountChange){
      //console.log('Business as usual')
      setAccountChange()
    }
  }

}, [accountChange])




useEffect(() => {

  if (tempWalletInfo){
    let cur_wallet = tempWalletInfo.cur_wallet

    if (activeWallet == cur_wallet){
      /*
        console.log(tempWalletInfo)
        console.log(tempWalletInfo.address)
        console.log(tempWalletInfo.stake_address)
        console.log(tempWalletInfo.lovelace_balance)
        console.log(tempWalletInfo.tokens_list)
      */
       setAddress(tempWalletInfo.address)
       setStakeAddress(tempWalletInfo.stake_address)
       setLovelace(tempWalletInfo.lovelace_balance)
       setTokens(tempWalletInfo.tokens_list)
       setIsBusy(false)
    }
}

}, [tempWalletInfo])



useEffect(() => {

    if (isVerifyBot){
        console.log(searchVal)
        checkDiscordInput(searchVal)
    }

},[searchVal])



useEffect(() => {
  setIsWalletLoading(isBusy)
}, [isBusy])


useEffect(() => {

  if (activeWallet){
     getWalletBalance()
  }

}, [activeWallet])

async function enableWallet(wallet){

      if (wallet == activeWallet){
          setIsOpen(false)
          getWalletBalance()
          //setIsBusy(false)
          return
      }

      /*
      console.log('NEW WALLET')
      console.log(wallet)

      console.log('OLD WALLET')
      console.log(activeWallet)
      */

      let cur_wallet = activeWallet
      setActiveWallet()





      try{
        let cardano = window.cardano[wallet]

              //console.log('window')
              //console.log(window.cardano)
      try{
        let walletAPI = await cardano.enable()
        setActiveWallet(wallet)
        updateWallet(wallet)
      }catch{
        setIsBusy(false)
        notifyError('Declined to connect to ' + wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet')
        setActiveWallet(cur_wallet)
      }
    }catch{
      setIsBusy(false)
      notifyError(wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet is not installed')
      setActiveWallet(cur_wallet)
    }
  } 



return (

   
<div>

 
{isOpen ?

<Grid container justifyContent="center" alignItems="center">


<div className='popup_2' style={{width:'100%', height:'100%', zIndex:'1350', overflow:'scroll'}}  > 
 <Grid container justifyContent="center" alignItems="center" style={{display:'flex',position:'absolute', top:'25%', height:'40%'}}>
   
        <div className='popup-inner_2' style={{width:'70%', maxWidth:'400px'}}>

        <button className='close-btn' onClick={() => {
           
           setIsOpen(false)
           setIsVerifyBot(false)
           setSearchVal('')

           }}><span style={{fontFamily:'shortstack'}}>X</span></button> 
 
        <Grid container justifyContent="center" alignItems="center" style={{height:'100%', width:'100%'}}>

            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}

              style={{height:'100%', width:'80%'}}
            >

    {!isVerifyBot ?

    <div>

      <DropDownItem onClick={() => {setIsBusy(true)
        enableWallet('nami')}}>
           {isBusy && activeWallet == 'nami' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
        <img src='/nami.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-20px', marginRight:'20px'}} / > }
        <ButtonText style={{fontSize:'1.1em'}} >NAMI</ButtonText>
      </DropDownItem>

      <DropDownItem onClick={() => {setIsBusy(true)
        enableWallet('eternl')}}>
        {isBusy && activeWallet == 'eternl' ? <div style={{marginLeft:'5px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
       <img src='/eternl.png' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'10px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Eternl</ButtonText>
    </DropDownItem>

     <DropDownItem onClick={() => {setIsBusy(true)
        enableWallet('yoroi')}}>
        {isBusy && activeWallet == 'yoroi' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
        <img src='/yoroi.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / > }
        <ButtonText style={{fontSize:'1.1em'}} >Yoroi</ButtonText>
    </DropDownItem>

    <DropDownItem onClick={() => {setIsBusy(true)
        enableWallet('flint')}}>
      {isBusy && activeWallet == 'flint' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='/flint.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Flint</ButtonText>
    </DropDownItem>


    <div style={{marginTop:'10px'}}>


    <Divider variant="middle">
        <p style={{color:'bisque', fontFamily:'shortstack'}}>Other</p>
    </Divider>


    </div>

    <DropDownItem onClick={() => {setIsVerifyBot(true)}}>
         <img src='VerifyBot.png' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >
        <ButtonText style={{fontSize:'1.1em'}} >VerifyBot</ButtonText>
    </DropDownItem>


    </div>

    : 


    <div>



        <h1>VerifyBot</h1>

        <p style={{color:'bisque'}}>
        Send '!pass' command in a direct message to <a href="https://discord.com/users/917378200592592910" target="_blank" style={{color:'bisque'}}>VerifyBot</a> in the Clay Mates Discord server
        in order to get a temporary authentication code
        </p>


        <div className={searchClassName} style={{marginTop:'60px', marginLeft:'0vw'}}>

    <SearchInput marginLeft={searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? '0px' : '0px'}>
        <input
          id="searching"
          type="text"
          value={searchVal}
          placeholder={searchPlaceholder}
          onChange={(event) => { 

            
            let cur_value = event.target.value
            setSearchVal(cur_value)

            if (cur_value == ''){
              //do nothing
            }else{
               setSearchClassName('searchFieldPressed')
             }

          }}

          onKeyPress={(event) => {

              //console.log(searchVal)

              if (event.key == 'Enter'){
                 //makeSearch(searchVal)
              }

            }}

   
          onFocus={() => {

          if (searchVal == ''){
              setSearchClassName('searchField focussed')
          }else{
            //if (clearSearch){
              //setSearchVal('')
              //setClearSearch(false)
           // }else{
              setSearchClassName('searchFieldPressed')
           //}
          }


          }}


          onBlur={() => {

            if (searchVal == ''){

               setSearchClassName('searchField')

            }else{

              setSearchClassName('searchFieldPressed')}

          }}
 
       />
</SearchInput>

</div>

    <div style={{position:'absolute', top:'15px', left:'20px'}}>
       <button className='CN-btn' onClick={()=>{setIsVerifyBot(false)}} style={{padding: '0px'}} ><span className="CN-text" style={{fontFamily:'shortstack', marginLeft:'-4px', marginTop:'-4px'}}>&lt;</span></button>
    </div>

    </div>


    }


        
        </Stack>




        </Grid>



        </div>


 </Grid>
    </div>
   

</Grid>


:



null}





   </div>





  

  )
}

export default AlertPopUp




